import React from 'react';
import * as styles from './Wysiwyg.module.scss';


interface Props extends React.HTMLAttributes<HTMLOrSVGElement>{
    children?: React.ReactNode;
    className?: string;
}

const Wysiwyg = ({
    children, className, ...rest
}: Props): JSX.Element => (
    <div
        className={`${styles.wysiwyg} ${className}`}
        {...rest}
    >
        {children}
    </div>
);

Wysiwyg.defaultProps = {
    children: null,
    className: ''
};

export default Wysiwyg;
