import React from 'react';
import SkeletonLabel from '../Skeleton/SkeletonLabel';
import SkeletonSection from '../Skeleton/SkeletonSection';
import Skeleton from '../Skeleton/Skeleton';
import SkeletonField from '../Skeleton/SkeletonField';

const FormSkeleton = (): JSX.Element => (
    <div>
        <Skeleton>
            <SkeletonSection>
                <SkeletonLabel />
                <SkeletonField />
            </SkeletonSection>
            <SkeletonSection>
                <SkeletonLabel />
                <SkeletonField />
            </SkeletonSection>
            <SkeletonSection>
                <SkeletonLabel />
                <SkeletonField />
            </SkeletonSection>
            <SkeletonSection>
                <SkeletonLabel />
                <SkeletonField />
            </SkeletonSection>
            <SkeletonSection>
                <SkeletonLabel />
                <SkeletonField />
            </SkeletonSection>

        </Skeleton>
    </div>
);

export default FormSkeleton;
