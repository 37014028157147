import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Card from '../Card/Card';
import Helvetica from '../Typography/Helvetica/Helvetica';

import * as calloutStyles from './callouts.module.scss';

const Callouts = (): JSX.Element => {
    const calloutClick = (link = '') => {
        trackCustomEvent({
            category: 'Brewin Website Link Click',
            action: 'click',
            label: link
        });
    };

    return (
        <div className={calloutStyles.callouts}>
            <Helvetica tag="p" typeset="paragraph">Alternatively, follow the links below to find out more about our services or try our free financial planning tools.</Helvetica>
            <Card
                tag="a"
                href="https://www.brewin.co.uk/relationship-with-money"
                title="Get your free Relationship with Money report"
                className={calloutStyles.calloutCard}
                onClick={() => calloutClick('https://www.brewin.co.uk/relationship-with-money')}
            >
                <Helvetica tag="span" typeset="textLink">Get your free Relationship with Money report</Helvetica>
                <Helvetica tag="span" typeset="smallcaps">Download</Helvetica>
            </Card>
            <Card
                tag="a"
                href="https://brewindolphin.focus-solutions.co.uk/digital/index.html"
                title="Try our free financial planning tools"
                className={calloutStyles.calloutCard}
                onClick={() => calloutClick('https://brewindolphin.focus-solutions.co.uk/digital/index.html')}
            >
                <Helvetica tag="span" typeset="textLink">Try our free financial planning tools</Helvetica>
                <Helvetica tag="span" typeset="smallcaps">Learn more</Helvetica>
            </Card>
        </div>
    );
};

export default Callouts;
