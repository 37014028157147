

interface Attr {
    key: string;
    value: string;
}

/**
 *
 * @param {*} src - script url
 * @param {*} callback - callback to run if script is initiated.
 * @param {*} attributes - html attributes to add to the script that's created.
 * @returns script - for testing
 */
const addScript = (src: string, callback?: ()=> void, attributes = [] as Attr[]): Element | null | HTMLScriptElement => {
    const scriptElem = document.querySelector(`script[src='${src}']`);

    /** Checks if the script has already been added to the DOM, run callback an exit. */
    if (scriptElem) {
        if (typeof callback === 'function') { callback(); }
        return scriptElem;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('src', src);

    if (attributes) {
        attributes.forEach((attr) => {
            script.setAttribute(attr.key, attr.value);
        });
    }

    if (typeof callback === 'function') { script.onload = callback; }
    document.body.appendChild(script);

    return script;
};

export default addScript;
