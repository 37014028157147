import React from 'react';

interface Props {
    children: React.ReactNode;
}

const SkeletonSection = ({ children }: Props): JSX.Element => <div className="space-y-1 max-w-sm">{children}</div>;


export default SkeletonSection;
