import React from 'react';
import * as styles from './Card.module.scss';

import DynamicComponent from '../DynamicComponent/DynamicComponent';

interface Props extends React.AllHTMLAttributes<HTMLLinkElement|HTMLOrSVGElement>{
  tag: keyof JSX.IntrinsicElements;
  className?: string;
  children?: React.ReactNode;
}

const Card = ({
    tag, children, className, ...rest
}: Props): JSX.Element => (
    <DynamicComponent
        tag={tag}
        className={`${styles.card} ${className}`}
        {...rest}
    >
        {children}
    </DynamicComponent>
);

Card.defaultProps = {
    className: null,
    children: null
};

export default Card;
