import React from 'react';

interface Props {
    children: React.ReactNode;
}

const Skeleton = ({ children }: Props): JSX.Element => (
    <div className="space-y-4">
        {children}
    </div>
);


export default Skeleton;
