import React, { useEffect } from 'react';
import useMarketoForm from '../../hooks/useMarketoForm';
import FormSkeleton from './FormSkeleton';

interface Props {
    onSuccess: ()=> void;
    aB: 'A' | 'B';
    formNumber: 3907 | 4079;
}

const Form = ({ aB, onSuccess, formNumber }: Props): JSX.Element => {
    const [submitted, mktoFormNumber] = useMarketoForm(aB, formNumber);

    useEffect(() => {
        if (submitted) onSuccess();
    }, [submitted]);

    return (
        <form id={`mktoForm_${mktoFormNumber}`} className="mktoFormOverride">
            <div className="mktoLoading">
                <FormSkeleton />
            </div>
        </form>
    );
};

export default Form;
