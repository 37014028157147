import { useEffect, useState } from 'react';
import addScript from '../helpers/addScript';

const marketoFormKey = '899-IDO-025';

/** Because they cant show and hide things in marketo easily we've had to do some jiggery pokery on here. Hiding the button in certain cases. */
const setupDropDownSubmitbuttonHide = () => {
    const contactTypeSelect = document.getElementById('contactType') as HTMLSelectElement | null;
    if (!contactTypeSelect) return;

    const mktoButton: HTMLButtonElement | null = document.querySelector('.mktoButton');

    if (mktoButton) mktoButton.style.display = 'none';

    contactTypeSelect.addEventListener('change', () => {
        if (contactTypeSelect.value === 'New Business') {
            if (mktoButton) mktoButton.style.display = 'initial';
        } else if (mktoButton) mktoButton.style.display = 'none';
    });
};

/**
 * dynamically add script for MktoForms2 to be available, when it is run a callback to load the form in
 *
 * https://docs.checkout.com/quickstart/integrate/frames/frames-reference
 */
const useMarketoForm = (aB: 'A' | 'B', marketoFormNumber: 3907 | 4079): [boolean, number] => {
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => { // eslint-disable-line
        addScript('//info.brewin.co.uk/js/forms2/js/forms2.min.js', () => {
            window.MktoForms2.loadForm('//info.brewin.co.uk', marketoFormKey, marketoFormNumber, (form) => {
                let abInput: HTMLInputElement | null = document.querySelector('input[name="a_b"]');
                if (abInput) abInput.value = aB;

                abInput = document.querySelector('input[name="abtestresult"]');
                if (abInput) abInput.value = aB;

                setupDropDownSubmitbuttonHide();

                // Add an onSuccess handler
                form.onSuccess(() => {
                    setSubmitted(true);
                    // return false to prevent the submission handler continuing with its own processing
                    return false;
                });
            });
        });
    }, []);

    return [submitted, marketoFormNumber];
};

export default useMarketoForm;
